import { environment } from '~/environments/environment';

/**
 * Function which resolves media user avatar url from user id
 *
 * @param id
 */
export const resolveUserAvatarUrl = (id: string) =>
  [environment.mediaUrl, 'media', 'resource', '200', 'user_avatar', id].join(
    '/',
  );
