// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.model';
import { APP_PROJECT_NAME, APP_VERSION } from './variables';

export const environment: Environment = {
  production: false,
  env: 'dev',
  version: APP_VERSION,
  projectName: APP_PROJECT_NAME,
  adminUrl: 'https://admin.bigheartapp.touch4dev.net',
  clientUrl: 'https://bigheartapp.touch4dev.net',
  mediaUrl: 'https://media.bigheartapp.touch4dev.net',
  nodeUrl: 'https://node-api.bigheartapp.touch4dev.net',
  ga: 'G-23H3VVWVGZ',
  featureFlags: {
    events: {
      enabled: true,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
